// Module
var code = `<div id="pinned" class="animationIf" ng-if="pinnedController.evaluations.length > 0" popover-is-open="pinnedController.shown()" popover-trigger="click outsideClick" ng-click="pinnedController.togglePinnedState()" popover-class="pinned-popover" uib-popover-template="'pinnedEvaluationsTemplate.html'" popover-title="{{ 'Component.Pinned' | translate }}" popover-placement="auto left-bottom"> <div class="btn btn-white btn-rounded"> <strong>{{ pinnedController.evaluations.length }}</strong> </div> </div> ${"<" + "script"} type="text/ng-template" id="pinnedEvaluationsTemplate.html"> <div class="list-group">
    <a ng-repeat="evaluation in pinnedController.evaluations" class="list-group-item"
       ng-class="{'export-not-allowed': !pinnedController.isExportAllowed(evaluation)}"
       uib-tooltip="{{ 'Message.ExcelExportNotAllowed' | translate }}"
       tooltip-enable="!pinnedController.isExportAllowed(evaluation)"
       tooltip-append-to-body="true"
       ui-sref="evaluation.general({ id: evaluation.id })">{{ evaluation.name || evaluation.code }}</a>
  </div>

  <div class="clearfix row">
    <div class="col-sm-12">
      <base-button ng-click="pinnedController.clear()" btn-context="default">{{ 'Generic.Filter.Clear' | translate }}</base-button>
      <export-button class="hidden-sm" get-evaluations="pinnedController.getPinnedForReport()" is-disabled="!pinnedController.hasExportableEvaluations()" button-type="default"></export-button>
      <dice-generate-report-button btn-style="btn btn-primary" get-evaluations="pinnedController.getPinnedForReport()"></dice-generate-report-button>
    </div>
  </div> ${"<" + "/script"}> `;
// Exports
module.exports = code;