// Module
var code = `<div class="modal-header"> <h3 class="modal-title"> {{ memberController.container.type.names | description }} <span class="badge">{{ memberController.container.data.length }}</span> <base-button btn-context="primary" class="balloon pull-right" ng-if="!memberController.model" ng-click="memberController.addNew()"> <i class="fa fa-plus"></i> <span translate="Generic.Create">Create</span> <div class="balloontext m-t-10" ng-show="memberController.members.length === 0"> <i class="fa fa-flag"></i> <span translate="Generic.StartHere">Start here</span> </div> </base-button> </h3> </div> <div class="modal-body p-t-0 p-b-0"> <div ng-if="memberController.members.length > 0" ng-include="'member-table.html'"></div> <div ng-include="'add-member.html'"></div> </div> <div class="modal-footer"> <close-button class="pull-left" ng-click="memberController.close()"></close-button> </div> ${"<" + "script"} type="text/ng-template" id="member-table.html"> <div class="table-responsive">
    <table class="table table-hover table-striped table-valign-middle m-b-0">
      <thead>
        <tr>
          <th sortable-table order="'fullName'" pageable="memberController.pageableCurrent" colspan="2">
            <span translate>Page.Member.Edit.Name</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr ng-repeat="member in memberController.members | paged: memberController.pageableCurrent"
            ng-class="{ 'disabled': member.disabled }">
          <td>
            <dice-member-avatar member="member" class="m-r-5"></dice-member-avatar>
            <span class="m-r-5">{{ member.fullName }}</span>
            <span class="badge badge-primary" ng-if="member.person">
              {{ member.person.code || member.person.externalId }}
            </span>
            <span class="badge badge-warning" ng-if="!member.person">
              <span translate="Page.Member.Edit.UnknownPerson">Unknown person</span>
            </span>
          </td>
          <td>
            <div class="pull-right">
              <!-- Add person to member -->
              <base-button
                ng-if="member.manual === true && !member.person"
                ng-click="memberController.addPerson(member)"
                btn-size="xs">
                <span class="fa fa-link"></span>
                <span translate="Page.Member.Edit.AddPerson">Add person</span>
              </base-button>

              <!-- Soft-delete (disable) for imported members -->
              <base-button
                ng-if="member.disabled !== true"
                ng-click="memberController.disable(member)"
                btn-context="danger" btn-size="xs">
                <span class="fa fa-times"></span>
                <span translate="Generic.Disable">Disable</span>
              </base-button>
              <base-button
                ng-if="member.disabled === true"
                ng-click="memberController.enable(member)"
                btn-context="default" btn-size="xs">
                <span class="fa fa-undo"></span>
                <span translate="Generic.Enable">Enable</span>
              </base-button>

              <!-- Delete for manual added members -->
              <delete-button
                ng-if="member.manual === true && member.disabled === true"
                on-delete="memberController.remove(member)"
                btn-size="xs">
              </delete-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="panel-footer clearfix">
    <dice-pagination
      page="memberController.members"
      pageable="memberController.pageableCurrent">
    </dice-pagination>
  </div> ${"<" + "/script"}> ${"<" + "script"} type="text/ng-template" id="add-member.html"> <form id="add-new-member" class="form-horizontal m-t-15" dice-form model="memberController.model" validator="member" name="memberForm" ng-if="memberController.model">
    <div class="panel panel-default">
      <div class="panel-heading">
        <h3 translate="Page.Member.Edit.New.Title">Create new member</h3>
        <div class="panel-heading-buttons">
          <cancel-button btn-type="outline" ng-click="memberController.reset()"></cancel-button>
        </div>
      </div>
      <div class="panel-body">
        <form-group label="Page.Member.Edit.New.Search">
          <input
            class="form-control form-input"
            id="search"
            name="search"
            required="required"
            placeholder="{{ 'Page.Member.Edit.New.SearchPlaceholder' | translate }}"
            ng-model="memberController.term"
            ng-maxlength="256"
            ng-change="memberController.onSearch()" />
        </form-group>

        <div ng-include="'add-existing.html'"></div>
        <div ng-include="'add-new.html'" ng-if="memberController.container.type.creatable === true"></div>
      </div>
    </div>
  </form> ${"<" + "/script"}> ${"<" + "script"} type="text/ng-template" id="add-new.html"> <div ng-show="memberController.term && memberController.person.id === undefined">
    <div class="alert alert-warning" ng-show="memberController.suggested.totalElements === 0" translate="Page.Member.Edit.New.Unknown">
      Person is not known in the system.
    </div>
    <form-input input-id="fullName" required="required" label="Page.Member.Edit.Name"></form-input>
    <form-input input-id="email" label="Page.Member.Edit.Email"></form-input>
    <save-button class="pull-right" type="submit" ng-click="memberController.save()"></save-button>
  </div> ${"<" + "/script"}> ${"<" + "script"} type="text/ng-template" id="add-existing.html"> <div ng-show="memberController.suggested.totalElements > 0">
    <div class="table-responsive">
      <table class="table table-hover table-striped table-valign-middle m-b-0">
        <thead>
          <tr>
            <th sortable-table order="'displayName'"
                pageable="memberController.pageableSearch"
                on-change="memberController.onSearch()"
                colspan="2">
              <i class="fa fa-search"></i>
              <span translate="Page.Member.Edit.New.Existing">Existing</span>
              <span class="badge">{{ memberController.suggested.totalElements }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr ng-repeat="suggested in memberController.suggested.content" ng-click="memberController.selectSuggested(suggested)" >
            <td>
              <span class="p-5 truncate">
                <dice-person-avatar person="suggested" class="p-r-5"></dice-person-avatar>
                <span>{{ suggested.displayName }}</span>
                <span class="m-l-5 badge badge-primary">{{ suggested.code || suggested.externalId }}</span>
              </span>
            </td>
            <td>
              <div class="pull-right">
                <div ng-if="suggested.available === true">
                  <a class="btn btn-default btn-xs" translate="Page.Member.Edit.New.Select">Select</a>
                </div>
                <div ng-if="suggested.available !== true">
                  <small translate="Page.Member.Edit.New.Exists">Already exists</small>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="panel-footer clearfix m-b-15">
      <dice-pagination
        page="memberController.suggested"
        pageable="memberController.pageableSearch"
        on-change="memberController.onSearch()">
      </dice-pagination>
    </div>
  </div> ${"<" + "/script"}> `;
// Exports
module.exports = code;