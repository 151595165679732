// Module
var code = `<div ng-if="previewQuestionController.templateUrl" ng-include="previewQuestionController.templateUrl"></div> ${"<" + "script"} type="text/ng-template" id="question_label.html"> <div>
    <question-tooltip
      tooltip="previewQuestionController.tooltip">
    </question-tooltip>
    <dice-html
      content="{{ previewQuestionController.question.labels | description: { language: previewQuestionController.language } }}">
    </dice-html>
  </div> ${"<" + "/script"}> ${"<" + "script"} type="text/ng-template" id="informative.html"> <required-label
    ng-if="previewQuestionController.question.required"
    class="pull-left m-r-5"
    ng-model="previewQuestionController.question.answer">
  </required-label>
  <div class="question-label m-b-10" ng-include="'question_label.html'"></div> ${"<" + "/script"}> ${"<" + "script"} type="text/ng-template" id="single_line_number.html"> <div class="form-group chars">
    <required-label
      ng-if="previewQuestionController.question.required"
      class="pull-left m-r-5"
      ng-model="previewQuestionController.question.answer">
    </required-label>
    <div class="question-label m-b-10" ng-include="'question_label.html'"></div>
    <input
      type="number"
      class="form-control no-shortcut"
      ng-model="previewQuestionController.question.answer"
      max="{{ previewQuestionController.maxNumber }}"
      min="0"
      name="sln-{{ previewQuestionController.question.id }}"
      question-watch="keyup"
      question-id="{{ previewQuestionController.question.id }}"
      debounce="500"
      ng-change="previewQuestionController.validateNumber({{ previewQuestionController.question.answer }})"
      callback="previewQuestionController.answer(question, value)"
    />
    <div ng-include="'left_over.html'"></div>
  </div> ${"<" + "/script"}> ${"<" + "script"} type="text/ng-template" id="single_line_text.html"> <div class="form-group chars">
    <required-label
      ng-if="previewQuestionController.question.required"
      class="pull-left m-r-5"
      ng-model="previewQuestionController.question.answer">
    </required-label>
    <div class="question-label m-b-10" ng-include="'question_label.html'"></div>
    <input
      type="text"
      class="form-control no-shortcut"
      ng-model="previewQuestionController.question.answer"
      maxlength="{{ previewQuestionController.question.characterLimit }}"
      name="slt-{{ previewQuestionController.question.id }}"
      question-watch="keyup"
      question-id="{{ previewQuestionController.question.id }}"
      debounce="500"
      callback="previewQuestionController.answer(question, value)"
      ng-trim="false"
    />
    <div ng-include="'left_over.html'"></div>
  </div> ${"<" + "/script"}> ${"<" + "script"} type="text/ng-template" id="multi_line_text.html"> <div class="form-group chars">
    <required-label
      ng-if="previewQuestionController.question.required"
      class="pull-left m-r-5"
      ng-model="previewQuestionController.question.answer">
    </required-label>
    <div class="question-label m-b-10" ng-include="'question_label.html'"></div>
    <textarea
      class="form-control msd-elastic no-shortcut"
      maxlength="{{ previewQuestionController.question.characterLimit }}"
      ng-model="previewQuestionController.question.answer"
      name="mlt-{{ previewQuestionController.question.id }}"
      question-watch="keyup"
      question-id="{{ previewQuestionController.question.id }}"
      debounce="500"
      callback="previewQuestionController.answer(question, value)"
      ng-trim="false"></textarea>
    <div ng-include="'left_over.html'"></div>
  </div> ${"<" + "/script"}> ${"<" + "script"} type="text/ng-template" id="left_over.html"> <char-left
    limit="{{ previewQuestionController.question.characterLimit }}"
    content="previewQuestionController.question.answer">
  </char-left> ${"<" + "/script"}> ${"<" + "script"} type="text/ng-template" id="multiple_choice_vertical_one_answer.html"> <div class="form-group">
    <required-label
      ng-if="previewQuestionController.question.required"
      class="pull-left m-r-5"
      ng-model="previewQuestionController.question.answer">
    </required-label>
    <div class="question-label m-b-10" ng-include="'question_label.html'"></div>
    <div class="radio" ng-repeat="ao in previewQuestionController.options track by \$index">
      <label>
        <input
          type="radio"
          name="cvo-{{ previewQuestionController.question.id }}"
          value="{{ ao.value }}"
          question-watch="change"
          question-id="{{ previewQuestionController.question.id }}"
          callback="previewQuestionController.answer(question, value)"
          ng-checked="ao.checked"
        />
        <span>{{ ao.label }}</span>
      </label>
    </div>
    <div class="radio" ng-show="previewQuestionController.question.allowOther">
      <label>
        <input
          type="radio"
          name="cvo-{{ previewQuestionController.question.id }}"
          value=""
          question-watch="change"
          question-id="{{ previewQuestionController.question.id }}"
          callback="previewQuestionController.answer(question, value)"
          ng-checked="previewQuestionController.question.hasOther"
        />
        <span translate>Generic.Other</span>
      </label>
    </div>
  </div>

  <div ng-include="'other.html'"></div> ${"<" + "/script"}> ${"<" + "script"} type="text/ng-template" id="multiple_choice_vertical_multiple_answers.html"> <div class="form-group">
    <required-label
      ng-if="previewQuestionController.question.required"
      class="pull-left m-r-5"
      ng-model="previewQuestionController.question.answer">
    </required-label>
    <div class="question-label m-b-10" ng-include="'question_label.html'"></div>
    <div class="checkbox" ng-repeat="ao in previewQuestionController.options track by \$index">
      <label>
        <input
          type="checkbox"
          name="cvm-{{ previewQuestionController.question.id }}"
          value="{{ ao.value }}"
          question-watch="change"
          question-id="{{ previewQuestionController.question.id }}"
          callback="previewQuestionController.answer(question, value)"
          ng-checked="ao.checked"
          ng-disabled="previewQuestionController.isAnswerOptionDisabled(ao.checked)"
        />
        <span>{{ ao.label }}</span>
      </label>
    </div>
    <div class="checkbox" ng-show="previewQuestionController.question.allowOther">
      <label>
        <input
          type="checkbox"
          name="cvo-{{ previewQuestionController.question.id }}"
          value=""
          question-watch="change"
          question-id="{{ previewQuestionController.question.id }}"
          callback="previewQuestionController.answer(question, value)"
          ng-checked="previewQuestionController.question.hasOther"
          ng-disabled="previewQuestionController.isAnswerOptionDisabled(previewQuestionController.question.hasOther)"
        />
        <span translate>Generic.Other</span>
      </label>
    </div>
  </div>

  <div ng-include="'other.html'"></div> ${"<" + "/script"}> ${"<" + "script"} type="text/ng-template" id="multiple_choice_horizontal_one_answer.html"> <div class="form-group">
    <required-label
      ng-if="previewQuestionController.question.required"
      class="pull-left m-r-5"
      ng-model="previewQuestionController.question.answer">
    </required-label>
    <div class="question-label m-b-10" ng-include="'question_label.html'"></div>
    <label class="radio-inline" ng-repeat="ao in previewQuestionController.options track by \$index">
      <input
        type="radio"
        name="cho-{{ previewQuestionController.question.id }}"
        value="{{ ao.value }}"
        question-watch="change"
        question-id="{{ previewQuestionController.question.id }}" callback="previewQuestionController.answer(question, value)"
        ng-checked="ao.checked"
      />
      <span>{{ ao.label }}</span>
    </label>
    <label class="radio-inline" ng-show="previewQuestionController.question.allowOther">
      <input
        type="radio"
        name="cho-{{ previewQuestionController.question.id }}"
        value=""
        question-watch="change"
        question-id="{{ previewQuestionController.question.id }}"
        callback="previewQuestionController.answer(question, value)"
        ng-checked="previewQuestionController.question.hasOther"
      />
      <span translate>Generic.Other</span>
    </label>
  </div>

  <div ng-include="'other.html'"></div> ${"<" + "/script"}> ${"<" + "script"} type="text/ng-template" id="multiple_choice_horizontal_multiple_answers.html"> <div class="form-group">
    <required-label
      ng-if="previewQuestionController.question.required"
      class="pull-left m-r-5"
      ng-model="previewQuestionController.question.answer">
    </required-label>
    <div class="question-label m-b-10" ng-include="'question_label.html'"></div>
    <label class="checkbox-inline" ng-repeat="ao in previewQuestionController.options track by \$index">
      <input
        type="checkbox"
        name="chm-{{ previewQuestionController.question.id }}"
        value="{{ ao.value }}"
        question-watch="change"
        question-id="{{ previewQuestionController.question.id }}"
        callback="previewQuestionController.answer(question, value)"
        ng-checked="ao.checked"
        ng-disabled="previewQuestionController.isAnswerOptionDisabled(ao.checked)"
      />
      <span>{{ ao.label }}</span>
    </label>
    <label class="checkbox-inline" ng-show="previewQuestionController.question.allowOther">
      <input
        type="checkbox"
        name="cvo-{{ previewQuestionController.question.id }}"
        value=""
        question-watch="change"
        question-id="{{ previewQuestionController.question.id }}"
        callback="previewQuestionController.answer(question, value)"
        ng-checked="previewQuestionController.question.hasOther"
        ng-disabled="previewQuestionController.isAnswerOptionDisabled(previewQuestionController.question.hasOther)"
      />
      <span translate>Generic.Other</span>
    </label>
  </div>

  <div ng-include="'other.html'"></div> ${"<" + "/script"}> ${"<" + "script"} type="text/ng-template" id="dropdown_list.html"> <div class="form-group">
    <required-label
      ng-if="previewQuestionController.question.required"
      class="pull-left m-r-5"
      ng-model="previewQuestionController.question.answer">
    </required-label>
    <div class="question-label m-b-10" ng-include="'question_label.html'"></div>
    <div class="select-box">
      <select
        name="dl-{{ previewQuestionController.question.id }}"
        class="form-control"
        question-watch="change"
        question-id="{{ previewQuestionController.question.id }}"
        callback="previewQuestionController.answer(question, value)"
        ng-options="ao.value as ao.label for ao in previewQuestionController.options" ng-model="previewQuestionController.question.answer">
      </select>
    </div>
  </div> ${"<" + "/script"}> ${"<" + "script"} type="text/ng-template" id="select_box.html"> <div class="form-group">
    <required-label
      ng-if="previewQuestionController.question.required"
      class="pull-left m-r-5"
      ng-model="previewQuestionController.question.answer">
    </required-label>
    <div class="question-label m-b-10" ng-include="'question_label.html'"></div>
    <div class="select-box">
      <select
        name="sb-{{ previewQuestionController.question.id }}"
        class="form-control"
        multiple
        question-watch="change"
        question-id="{{ previewQuestionController.question.id }}"
        callback="previewQuestionController.answer(question, value)"
        ng-options="ao.value as ao.label disable when previewQuestionController.isAnswerOptionDisabled(previewQuestionController.question.answer.includes(ao.value)) for ao in previewQuestionController.options"
        ng-model="previewQuestionController.question.answer"
      ></select>
    </div>
  </div> ${"<" + "/script"}> ${"<" + "script"} type="text/ng-template" id="matrix_header.html"> <div class="row matrix-header" ng-if="previewQuestionController.differentPrevious">
    <div class="col-sm-4"></div>
    <div class="col-sm-8 table-header" style="display:table;">
      <strong
        style="display:table-cell;text-align:center;"
        ng-style="{ 'width': previewQuestionController.matrixWidth + '%'}"
        ng-repeat="ao in previewQuestionController.options track by \$index">{{ ao.label }}</strong>
    </div>
  </div> ${"<" + "/script"}> ${"<" + "script"} type="text/ng-template" id="matrix_one_answer.html"> <div class="hidden-sm hidden-xs">
    <div ng-include="'matrix_header.html'"></div>
    <div class="row p-t-10 p-b-10 matrix-row {{ previewQuestionController.differentNext ? 'matrix-row-last' : '' }}">
      <div class="col-sm-4">
        <required-label
          ng-if="previewQuestionController.question.required"
          class="pull-left m-r-5"
          ng-model="previewQuestionController.question.answer">
        </required-label>
        <div ng-include="'question_label.html'"></div>
      </div>
      <div class="col-sm-8">
        <label
          ng-repeat="ao in previewQuestionController.options track by \$index"
          style="text-align:center; display:inline-block;"
          ng-style="{ 'width': (100 / previewQuestionController.options.length) + '%'}">
          <input
            type="radio"
            name="ms-{{ previewQuestionController.question.id }}"
            value="{{ ao.value }}"
            question-watch="change"
            question-id="{{ previewQuestionController.question.id }}"
            callback="previewQuestionController.answer(question, value)"
            ng-checked="ao.checked"
          />
        </label>
      </div>
    </div>
  </div>
  <div class="hidden-md hidden-lg" ng-include="'multiple_choice_vertical_one_answer.html'"></div> ${"<" + "/script"}> ${"<" + "script"} type="text/ng-template" id="matrix_multiple_answers.html"> <div class="hidden-sm hidden-xs">
    <div ng-include="'matrix_header.html'"></div>
    <div class="row p-t-10 p-b-10 matrix-row {{ previewQuestionController.differentNext ? 'matrix-row-last' : '' }}">
      <div class="col-sm-4">
        <required-label
          ng-if="previewQuestionController.question.required"
          class="pull-left m-r-5"
          ng-model="previewQuestionController.question.answer">
        </required-label>
        <div ng-include="'question_label.html'"></div>
      </div>
      <div class="col-sm-8">
        <label
          ng-repeat="ao in previewQuestionController.options track by \$index"
          style="text-align:center; display:inline-block;"
          ng-style="{ 'width': (100 / previewQuestionController.options.length) + '%'}">
          <input
            type="checkbox"
            name="mm-{{ previewQuestionController.question.id }}"
            value="{{ ao.value }}"
            question-watch="change"
            question-id="{{ previewQuestionController.question.id }}"
            callback="previewQuestionController.answer(question, value)"
            ng-checked="ao.checked"
            ng-disabled="previewQuestionController.isAnswerOptionDisabled(ao.checked)"
          >
        </label>
      </div>
    </div>
  </div>
  <div class="hidden-md hidden-lg" ng-include="'multiple_choice_vertical_multiple_answers.html'"></div> ${"<" + "/script"}> ${"<" + "script"} type="text/ng-template" id="image.html"> <required-label
    ng-if="previewQuestionController.question.required"
    class="pull-left m-r-5"
    ng-model="previewQuestionController.question.answer"
  ></required-label>
  <div class="question-label m-b-10" ng-include="'question_label.html'"></div>
  <div class="clearfix m-b-15">
    <img ng-src="/api/images/{{ previewQuestionController.question.image }}" class="img-responsive">
  </div> ${"<" + "/script"}> ${"<" + "script"} type="text/ng-template" id="member_list.html"> <required-label
    ng-if="previewQuestionController.question.required"
    class="pull-left m-r-5"
    ng-model="previewQuestionController.question.answer">
  </required-label>
  <div class="question-label m-b-10" ng-include="'question_label.html'"></div>
  <div class="table-responsive" ng-repeat="(type, members) in previewQuestionController.membersPerType">
    <table class="table table-hover table-ultra-condensed table-valign-middle">
      <thead>
      <tr>
        <th colspan="3">{{ members[0].type.names | description: { language: previewQuestionController.language } }}</th>
      </tr>
      </thead>
      <tbody>
      <tr class="table-fit-columns" ng-repeat="member in members"
          ng-click="previewQuestionController.onMemberSelect(member)">
        <td class="table-person-image">
          <dice-member-avatar member="member" class="pull-right"></dice-member-avatar>
        </td>
        <td ng-class="{ 'text-bold' : member.active }" class="truncate-container">
          <span>{{ member.fullName || member.person.displayName || member.person.code }}</span>
        </td>
        <td>
          <toggle-input class="pull-right" id="member-{{ member.id }}" name="member-{{ member.id }}" ng-model="member.active"
                        ng-disabled="previewQuestionController.disabled"></toggle-input>
        </td>
      </tr>
      </tbody>
    </table>
  </div> ${"<" + "/script"}> ${"<" + "script"} type="text/ng-template" id="other.html"> <div class="row m-b-10" ng-if="previewQuestionController.question.hasOther">
    <div class="col-xs-12">
      <input
        type="text"
        class="form-control no-shortcut"
        ng-model="previewQuestionController.question.other"
        maxlength="{{ previewQuestionController.question.characterLimit }}"
        name="slt-{{ previewQuestionController.question.id }}-other"
        question-watch="keyup"
        question-id="{{ previewQuestionController.question.id }}"
        debounce="500"
        callback="previewQuestionController.answer(question, value)"
        placeholder="{{ 'Generic.Other' | translate }}"
        ng-trim="false">
      <div ng-include="'left_over.html'"></div>
    </div>
  </div> ${"<" + "/script"}> `;
// Exports
module.exports = code;